enum Actions {
  FETCH_REFERENCES = 'fetchReferences',
}

enum Mutations {
  SET_REFERENCES = 'setReferences',
  SET_ACTION_ERROR = 'setReferenceErrors',
}

enum Getters {
  GET_REFERENCES_LISTS = 'getReferencesLists',
  GET_ACTION_ERROR = 'getReferenceErrors',
}

export { Actions, Mutations, Getters };
